ClassicEditor.defaultConfig = {
	toolbar: {
		items: [
			'heading',
			'|',
			'bold',
			'italic',
			'link',
			'|',
			'bulletedList',
			'numberedList',
			'|',
			'outdent',
			'indent',
			'blockQuote',
			'|',
			'undo',
			'redo'
		]
	},
	language: 'fr'
};
